<template>
  <div class="container">
    <Header :active="6" />
    <div class="banner-box">
      <el-image
        class="banner-box-img"
        src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/banner.png"
        lazy
      ></el-image>
      <div class="banner-box-text-box">
        <div class="banner-box-text-box-title">
          <div>交流研究</div>
        </div>
        <div class="banner-box-text-box-tips">Communication research</div>
        <div
          class="horizontal-line"
          :style="{ marginTop: pxToRem(21), background: 'white' }"
        ></div>
      </div>
    </div>
    <div class="section1">
      <div class="section1-header-box">
        <div class="section1-header-box">
          <div class="section1-header-box-title">新闻报道</div>
          <div class="section1-header-box-tips">
            <div class="section1-header-box-tips-text">News reports</div>
            <div class="section1-header-box-tips-morecont">
              <a href="javascript:;" @click="handleChangePage('report')"
                >换一批 ></a
              >
              <!-- <a href="javascript:;">
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_morebtn_gray.png" alt="">
              </a> -->
            </div>
          </div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(21) }"
          ></div>
        </div>
      </div>
      <div class="section1-box">
        <div class="section1-box-textcont">
          <div
            v-for="(item, ind) in reportList[reportActiveIndex]"
            :key="ind"
            class="section1-box-textcont-items"
            @mouseover="handleMouseover(ind)"
            @click="handleGotoDetail(item.url, item.id, item.navDesc)"
          >
            <div class="section1-box-textcont-items-data">
              <p class="section1-box-textcont-items-data-month">
                {{ item.month }}
              </p>
              <p class="section1-box-textcont-items-data-year">
                {{ item.year }}
              </p>
            </div>
            <div class="section1-box-textcont-items-cont">
              <p class="section1-box-textcont-items-cont-title">
                {{ item.title }}
              </p>
              <p class="section1-box-textcont-items-cont-text">
                {{ item.text }}
              </p>
            </div>
            <a class="section1-box-textcont-items-more" href="javascript:;">
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_morebtn_gray.png" alt="" />
            </a>
          </div>
        </div>
        <div class="section1-box-bannercont">
          <div class="carousel-box">
            <el-carousel
              :autoplay="false"
              :height="pxToRem(carouselHeight)"
              indicator-position="outside"
              trigger="click"
              style="width: 100%"
              ref="carousel"
            >
              <el-carousel-item
                v-for="(item, i) in reportList[reportActiveIndex]"
                :key="i"
              >
                <div class="carousel-item">
                  <img
                    class="carousel-img"
                    ref="carouselImg"
                    style="width: 100%"
                    :src="item.img"
                    alt=""
                  />
                  <div class="carousel-item-text-box">
                    <div class="carousel-item-text-box-line"></div>
                    <div class="carousel-item-text-box-title">
                      {{ item.title }}
                    </div>
                    <div class="carousel-item-text-box-info">
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="section2-header-box">
        <div class="section2-header-box">
          <div class="section2-header-box-title">行业研究</div>
          <div class="section2-header-box-tips">
            <div class="section2-header-box-tips-text">Industry research</div>
            <div class="section2-header-box-tips-morecont">
              <a href="javascript:;" @click="handleChangePage('industry')"
                >换一批 ></a
              >
              <!-- <a href="javascript:;">
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_morebtn_gray.png" alt="">
              </a> -->
            </div>
          </div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(21) }"
          ></div>
        </div>
      </div>
      <div class="section2-box">
        <div
          class="section2-box-items"
          v-for="(item, ind) in industryList[industryActiveIndex]"
          :key="ind"
          @click="handleGotoDetail(item.url, item.id)"
        >
          <div
            class="section2-box-items-cont"
            :style="{
              background: '#fff url(' + item.img + ') 0% 0%/auto 100% no-repeat',
            }"
          >
          <!-- backgroundSize: 'auto 100%', -->
            <div class="section2-box-items-cont-text">
              <p class="section2-box-items-cont-text-title">{{ item.title }}</p>
              <p class="section2-box-items-cont-text-desc">{{ item.text }}</p>
            </div>
            <a class="section2-box-items-cont-more" href="javascript:;">
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_morebtn_green.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section3">
      <div class="section3-header-box">
        <div class="section3-header-box">
          <div class="section3-header-box-title">常见问题</div>
          <div class="section3-header-box-tips">
            <div class="section3-header-box-tips-text">
              Frequently asked questions
            </div>
            <div class="section3-header-box-tips-morecont">
              <a href="javascript:;" @click="handleChangePage('question')"
                >换一批 ></a
              >
              <!-- <a href="javascript:;">
                <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_morebtn_gray.png" alt="">
              </a> -->
            </div>
          </div>
          <div
            class="horizontal-line"
            :style="{ marginTop: pxToRem(21) }"
          ></div>
        </div>
      </div>
      <div class="section3-box">
        <div
          class="section3-box-items"
          v-for="(item, ind) in questionsList[questionActiveIndex]"
          :key="ind"
        >
          <div class="section3-box-items-cont">
            <p
              class="section3-box-items-cont-title"
            >
            <!-- @click="handleShowDetail(ind)" -->
              <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/icon_question.png" alt="" />
              {{ item.title }}
            </p>
            <p class="section3-box-items-cont-desc">
              <!-- {{ item.actived ? item.desc : item.subTitle }} -->
              {{item.desc}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "communication",
  data() {
    return {
      carouselHeight: 503,
      swiperIndex: 0,
      reportActiveIndex: 0,
      industryActiveIndex: 0,
      questionActiveIndex: 0,
      reportList: [
        [
          {
            title: "《柳叶刀》子刊大规模分析：戴上运动设备，5个月轻松减重1公斤",
            text: "2022年4月13日，国际知名医学期刊《Nature Medicine》刊发了北京大学生命科学学院邓宏魁教授的最新研究结果，揭示了关于干细胞治疗糖尿病的实验进展及研究成果",
            year: "2022年",
            month: "8",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report9_1.jpg",
            active: false,
            id: "2e2d7fbdea1afc51",
            url: "/newsReport",
            num: 9
          },
          {
            title: "我国干细胞制备技术获得重要突破",
            text: "2022年4月13日，国际知名医学期刊《Nature Medicine》刊发了北京大学生命科学学院邓宏魁教授的最新研究结果，揭示了关于干细胞治疗糖尿病的实验进展及研究成果",
            year: "2022年",
            month: "4",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report2_1.png",
            active: false,
            id: "9d4c2f636f067f89",
            url: "/newsReport",
            num: 2
          },
          {
            title: "我国糖尿病疾病负担严重，亟需加大防控工作力度",
            text: "2021年12月28日，国际权威医学期刊《美国医学会杂志（JAMA）》（影响因子56.3）全文发表了我国学者关于2013-2018年中国糖尿病患病和治疗状况的研究“Prevalence and treatment of diabetes in China, 2013-2018”",
            year: "2021年",
            month: "12",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report1_1.png",
            active: true,
            id: "a0b923820dcc509a",
            url: "/newsReport",
            num: 1
          },
          {
            title: "人工智能（AI）正在引领一场新的科学革命",
            text: "编者按：因为声称一个人工智能具备了“人格”，谷歌的一名研究人员被公司强制带薪休假，人工智能又火了一把。事实上，媒体的笔触和大众的眼球似乎总是放在聊天、画画这些更容易理解的事情上，但人工智能在科学领域所取得的进展尽管不那么哗众取宠，却对人类进步产生了实实在在的影响，甚至可以说，AI正在引领一场新的科学革命。本文是对人工智能在科学领域发挥的作用所进行的综述，文章来自编译。",
            year: "2021年",
            month: "8",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report8_1.png",
            active: true,
            id: "fb98ab9159f51fd0",
            url: "/newsReport",
            num: 8
          },],
          [
          {
            title:
              "1 型糖尿病同种异体脐带间充质基质细胞的重复移植：一项开放平行对照临床研究",
            text: "1 型糖尿病 (T1D) 中 β 细胞功能的保存或恢复仍然是一个有吸引力且具有挑战性的治疗目标。间充质基质细胞 (MSCs) 是具有高免疫调节能力的多能细胞，已成为许多免疫疾病的有前途的细胞疗法。本研究的目的是检查一次重复移植同种异体 MSCs 在 T1D 个体中的有效性和安全性。",
            year: "2021年",
            month: "6",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/report-img1.png",
            active: true,
            id: "bbce2345d7772b06",
            url: "/newsReport",
            num: 5
          },{
            title:
              "糖尿病前期以及新发2型糖尿病在一年以内的，加强管理，一半以上可以逆转",
            text: "6月20日，央视新闻频道在微博、快手等平台播放了一个短视频，在视频中解放军总医院内分泌科主任母义明教授说：“糖尿病是可以逆转的”",
            year: "2019年",
            month: "3",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/reports/report7_1.png",
            active: false,
            id: "ceea167a5a36dedd",
            url: "/newsReport",
            num: 7
          },
          {
            title: 'AIoT 时代的智能无线感知：特征、算法、数据集',
            text: '人类对物理世界的感知经历了从主观感受到传感器再到传感网的发展阶段，如图1所示。在传感器变得越来越小、数据采集变得越来越普及的同时，有一个问题也日益突出——传感系统的部署成本太高',
            year: '2020年',
            month: '2',
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/report-img1.png",
            active: false,
            id: "c124a10e0db5e4b9",
            url: "/industryResearch",
            navDesc: '新闻报道',
            num: 13
          },
          {
            title: "澳研究发现肝脏分泌的一种蛋白质可降血糖",
            text: "新华社悉尼9月6日电（记者陈宇）澳大利亚等国研究人员发现，由肝脏自然分泌的一种蛋白质可以降低患糖尿病实验鼠的血糖水平，希望未来能够在此基础上，开发出治疗2型糖尿病的新药。相关研究成果已发布在新一期美国《科学·转化医学》杂志上。",
            year: "2018年",
            month: "9",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/report-img1.png",
            active: false,
            id: "5a880faf6fb5e608",
            url: "/newsReport",
            num: 6
          },],
          [
          {
            title: "一种蛋白质与糖尿病相关",
            text: "日本京都府立医科大学近日发表的最新研究结果显示，一种名为p53的蛋白质与糖尿病相关，抑制这种蛋白质的功能可以改善实验鼠胰岛素分泌和糖耐受水平。研究人员称，这一发现有助于开发治疗糖尿病的新药。",
            year: "2014年",
            month: "2",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/report-img1.png",
            active: false,
            id: "a2f3e71d9181a67b",
            url: "/newsReport",
            num: 4
          },
          {
            title: "FDA谨慎对待Bristol阿斯利康新糖尿病治疗药物",
            text: "伦敦-美国食品和药品管理局工作人员周二表示，他们对阿斯利康和百时美施贵宝的新型糖尿病治疗药物dapagliflozin的风险与利益表示担忧。",
            year: "2013年",
            month: "12",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/report-img1.png",
            active: false,
            id: "4b5ce2fe28308fd9",
            url: "/newsReport",
            num: 3
          },
        ],
      ],
      industryList: [
        [
          {
            title: "日常生活（ADL）",
            text: "老年人日常生活能力残疾的发生率，作为保护因素的体力活动以及对非正式和正式护理的需求",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img1.png",
            url: "/industryResearch",
            id: 'a0b923820dcc509a',
            num: 1
          },
          {
            title: "生活监护",
            text: "人工智能——以数字健康平台和可穿戴设备为动力，改善辅助生活社区中老年人的生活效果：试点干预研究",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img2.png",
            url: "/industryResearch",
            id: '9d4c2f636f067f89',
            num: 2
          },
          {
            title: "健康监护",
            text: "使用家用传感器和机器深度学习技术对痴呆症患者的日常生活活动进行健康管理和模式分析",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img3.png",
            url: "/industryResearch",
            id: '4b5ce2fe28308fd9'
          },
        ],
        [
          {
            title: "跌倒预测",
            text: "新型传感技术在老年人跌倒风险评估中的应用：系统评价",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img4.png",
            url: "/industryResearch",
            id: 'a2f3e71d9181a67b',
            num: 4
          },
          {
            title: "风湿患者护理",
            text: "数字健康：风湿患者护理的新维度",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img5.png",
            url: "/industryResearch",
            id: 'bbce2345d7772b06',
            num: 5
          },
          {
            title: "日常生活活动（ADL）",
            text: "老年人ADL致残的发生率、身体活动作为保护因素以及对非正式或正式护理的需求——SNAC-N研究结果",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img6.png",
            url: "/industryResearch",
            id: '5a880faf6fb5e608',
            num: 6
          },
        ],
        [
          {
            title: "沮丧/抑郁",
            text: "处于长期护理状态下老年人抑郁状况与高水平功能需求之间的关联分析",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img7.png",
            url: "/industryResearch",
            id: 'ceea167a5a36dedd',
            num: 7
          },
          {
            title: "饮食监测",
            text: "老年女性的饮食模式和2型糖尿病风险：早餐习惯和饮食频率",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img8.png",
            url: "/industryResearch",
            id: 'fb98ab9159f51fd0',
            num: 8
          },
          {
            title: "认知障碍",
            text: "阿尔茨海默症患者群体中饮食障碍与失智程度的关系",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img9.png",
            url: "/industryResearch",
            id: '2e2d7fbdea1afc51',
            num: 9
          },
        ],[
          {
            title: "生活感知",
            text: "成年人群体中客观测量的久坐时间与身体活动的可靠性",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img10.png",
            url: "/industryResearch",
            id: '02a44259755d38e6',
            num: 10
          },
          {
            title: "跌倒评估",
            text: "老人群体中的身体活动与跌倒风险评估的不同概念",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img11.png",
            url: "/industryResearch",
            id: 'd9caa6e02c990b0a',
            num: 11
          },
          {
            title: "生活辅助",
            text: "面向老龄化社会的创新辅助生活工具、远程监控技术、人工智能驱动的解决方案和机器人系统",
            img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/communication/industry-img12.png",
            url: "/industryResearch",
            id: '6fe97759aa27a0c9',
            num: 12
          },
        ],
      ],
      questionsList: [
        [
          {
            title: "哪里可以买到产品？",
            subTitle:
              "请通过公众号、电话或公司mail联系购买、咨询，谢谢。",
            desc: "请通过公众号、电话或公司mail联系购买、咨询，谢谢。",
            actived: false,
          },
          {
            title: "手环或手表需要更换电池吗？",
            subTitle:
              "手环或手表无需更换电池，一次充电，正常使用情况下，可续航3-7天。",
            desc: "手环或手表无需更换电池，一次充电，正常使用情况下，可续航3-7天。",
            actived: false,
          },
          {
            title: "家用信标需要更换电池吗？",
            subTitle:
              "家用信标一般情况下，可用8年以上；",
            desc: "家用信标一般情况下，可用8年以上；",
            actived: false,
          },
          {
            title: "血糖仪、血压计称哪里购买？有优惠吗？",
            subTitle:
              "家用血糖仪和血压计，我们采用的是具有医疗资质的罗氏家用血糖仪和欧姆龙家用血压计，可通过我们代购（有优惠）...",
            desc: "家用血糖仪和血压计，我们采用的是具有医疗资质的罗氏家用血糖仪和欧姆龙家用血压计，可通过我们代购（有优惠），也可通过其官方途径购买；",
            actived: false,
          },
        ],
        [
          {
            title: "监测数据需要自己录入系统吗？",
            subTitle:
              "无需自己录入，系统通过蓝牙自行读入；",
            desc: "无需自己录入，系统通过蓝牙自行读入；",
            actived: false,
          },
          {
            title: "我的检验、检测数据医生会看吗？",
            subTitle:
              "只有在您选择了主治医生，医生才能查阅您的病史信息；未经同意，任何人无法获取您的信息；",
            desc: "只有在您选择了主治医生，医生才能查阅您的病史信息；未经同意，任何人无法获取您的信息；",
            actived: false,
          }
        ],
      ],
    };
  },
  methods: {
    swiperInit() {
      setInterval(() => {
        this.swiperIndex = this.swiperIndex == 1 ? 0 : 1;
      }, 3000);
    },
    handleMouseover(ind) {
      this.$refs.carousel.setActiveItem(ind);
    },
    handleShowDetail(ind) {
      this.questionsList[this.questionActiveIndex][ind].actived = !this.questionsList[this.questionActiveIndex][ind].actived;
    },
    // 翻页
    handleChangePage(val) {
      if (val == "report") {
        let ind = this.reportActiveIndex;
        ind++;
        this.reportActiveIndex = ind % this.reportList.length;
      } else if (val == "industry") {
        let ind = this.industryActiveIndex;
        ind++;
        this.industryActiveIndex = ind % this.industryList.length;
      } else if (val == "question") {
        let ind = this.questionActiveIndex;
        ind++;
        this.questionActiveIndex = ind % this.questionsList.length;
      }
    },
    handleGotoDetail(url, id, navDesc) {
      if (id) {
        if (navDesc) {
          this.$router.push({ path: url, query: { id, navDesc } });
        } else {
          this.$router.push({ path: url, query: { id } });
        }
      } else {
        this.$router.push({ path: url });
      }
    },
  },
  mounted() {
    this.swiperInit();
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.title-box {
  .title {
    font-family: "UI-Bold";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.banner-box {
  position: relative;
  &-img {
    width: 100%;
  }
  &-text-box {
    position: absolute;
    width: 1200px;
    box-sizing: border-box;
    // height: 300px;
    // left: 360px;
    // right: 0;
    top: 128px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: white;
    &-title {
      font-size: 64px;
      font-family: "UI-Bold";
    }
    &-tips {
      font-family: "CN-Light";
      font-size: 31px;
      margin-top: 17px;
    }
    &-info {
      font-family: "CN-Light";
      line-height: 40px;
      color: #213763;
      width: 427px;
    }
    &-btn {
      width: 120px;
      height: 30px;
      margin-top: 60px;
      cursor: pointer;
    }
    &-btn:hover {
      transform: scale(1.02);
      transition: all 0.5s;
    }
  }
}
.section1 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-text {
        font-size: 31px;
        font-family: "CN-Light";
        color: #90c446;
        margin-top: 5px;
      }
      &-morecont {
        a {
          font-size: 21px;
          color: #727171;
          text-decoration: none;
          padding: 0 18px;
          border-left: 3px solid #90c446;
          font-weight: bold;
        }
        img {
          width: 102px;
          height: 20px;
        }
      }
    }
  }
  &-box {
    // height: 535px;
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    &-textcont {
      width: 724px;
      &-items {
        display: flex;
        align-items: flex-end;
        padding: 20px 0;
        border-bottom: 1px solid #d2d3d4;
        &-data {
          border-right: 1px solid #d2d3d4;
          padding-right: 5px;
          margin-right: 20px;
          text-align: center;
          &-month {
            font-size: 34px;
            font-weight: bold;
            color: #1f3876;
          }
          &-year {
            width: 60px;
            font-size: 15px;
            color: #727171;
          }
        }
        &-cont {
          flex: 1;
          &-title {
            font-size: 28px;
            color: #1f3876;
            font-weight: 500;
            margin-bottom: 5px;
            width: 545px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          &-text {
            font-size: 20px;
            color: #727171;
            width: 545px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &-more {
          width: 90px;
          padding-right: 10px;
          img {
            width: 90px;
            height: 18px;
          }
        }
      }
      &-items:hover {
        cursor: pointer;
        .section1-box-textcont-items-data {
          .section1-box-textcont-items-data-month {
            color: #90c446;
          }
        }
        .section1-box-textcont-items-cont {
          .section1-box-textcont-items-cont-title {
            color: #90c446;
          }
        }
      }
      &-items:last-child {
        border-bottom: 0;
      }
    }
    &-bannercont {
      width: 428px;
      img {
        width: 100%;
      }
    }
  }
}
.section2 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-text {
        font-size: 31px;
        font-family: "CN-Light";
        color: #90c446;
        margin-top: 5px;
      }
      &-morecont {
        a {
          font-size: 21px;
          color: #727171;
          text-decoration: none;
          padding: 0 18px;
          border-left: 3px solid #90c446;
          font-weight: bold;
        }
        img {
          width: 102px;
          height: 20px;
        }
      }
    }
  }
  &-box {
    &-items {
      margin-top: 30px;
      width: 1198px;
      &-cont {
        padding: 0 70px 45px 368px;
        height: 188px;
        display: flex;
        align-items: flex-end;
        box-sizing: border-box;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.8s;
        border-radius: 15px;
        &-text {
          width: 572px;
          &-title {
            font-size: 32px;
            line-height: 32px;
            color: #1f3876;
            font-weight: 500;
            margin-bottom: 15px;
            border-left: 4px solid #90c446;
            padding-left: 10px;
          }
          &-desc {
            padding-left: 14px;
            font-size: 20px;
            line-height: 26px;
            color: #727171;
            height: 52px;
          }
        }
        &-more {
          img {
            width: 90px;
            height: 18px;
          }
        }
      }
      &-cont:hover {
        transform: scale(1.02);
      }
    }
    &-items:first-child {
      margin-top: 58px;
    }
  }
}
.section3 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 125px;
  padding-bottom: 210px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &-text {
        font-size: 31px;
        font-family: "CN-Light";
        color: #90c446;
        margin-top: 5px;
      }
      &-morecont {
        a {
          font-size: 21px;
          color: #727171;
          text-decoration: none;
          padding: 0 18px;
          border-left: 3px solid #90c446;
          font-weight: bold;
        }
        img {
          width: 102px;
          height: 20px;
        }
      }
    }
  }
  &-box {
    &-items {
      margin: 30px 0 0 60px;
      width: 1084px;
      &-cont {
        border-bottom: 1px solid #b0b1b3;
        &-title {
          display: flex;
          align-items: center;
          font-size: 32px;
          color: #1f3876;
          font-weight: 500;
          margin-bottom: 23px;
          cursor: pointer;
          img {
            width: 39px;
            height: 39px;
            margin-right: 35px;
          }
        }
        &-desc {
          padding-bottom: 27px;
          font-size: 20px;
          line-height: 26px;
          color: #727171;
        }
      }
    }
  }
}
.carousel-box {
  width: 100%;
  .el-carousel {
    position: relative;
    background: #fff;
    border-radius: 20px;
  }
  .carousel-item {
    .carousel-img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .carousel-item-text-box {
      margin-top: 250px;
      height: 220px;
      padding: 35px 0 0 39px;
      background: rgba(144,196,70,.6);
      &-line {
        width: 35px;
        height: 5px;
        background: #fff;
        margin-bottom: 5px;
      }
      &-title {
        font-family: "CN-Bold";
        font-size: 25px;
        color: #1f3876;
        line-height: 32px;
        width: 340px;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      &-info {
        font-size: 15px;
        line-height: 19px;
        color: #1f3876;
        margin-top: 20px;
        width: 340px;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  /deep/ .el-carousel__indicators {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
  }
  /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
    width: 18px;
    height: 7px;
    border-radius: 20px;
    background: white;
    opacity: 1;
    margin-right: 10px;
  }
  /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background: #1f3876;
    width: 40px;
  }
}
</style>
